import React from "react";

export const Logomark = (props: {
    style?: React.CSSProperties;
    alt?: string;
    fill?: string;
}): JSX.Element => (
    <svg
        viewBox="0 0 92 110"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12 76.4C4.29999 67.9 0.0999876 57 0.199988 45.4C0.399988 21.6 19.5 1.49995 43.3 0.199955C69.6 -1.20004 91.5 19.8 91.5 45.8C91.5 52.3 90.1 58.7 87.5 64.6C86.8 66.1 85 66.8 83.5 66L78.5 63.3C77.2 62.6 76.6 61 77.2 59.7C79.1 55.4 80.1 50.6 80.1 45.8C80.1 26.4 63.9 10.7 44.3 11.6C26.6 12.4 12.1 27.3 11.7 45C11.5 53.8 14.6 62.1 20.4 68.5C21.4 69.6 21.3 71.3 20.3 72.4L16.4 76.5C15.1 77.7 13.2 77.6 12 76.4Z"
            fill="#0043FF"
        />
        <path
            d="M44 109L18.4 83.4C17.3 82.3 17.3 80.5 18.4 79.4L22.4 75.4C23.5 74.3 25.3 74.3 26.4 75.4L44 92.8C45.1 93.9 46.9 93.9 48 92.8C48 92.8 53.9999 87.3 55.6999 85.1C63.0999 75.7 59.5999 61.4 47.5999 60.1C47.0999 60 46.5 60 46 60C38.1 59.9 31.2 54.1 29.9 46.6C29 41.2 30.7999 35.8 34.6999 32.1C39.4999 27.6 46.6 26.3 52.9 29.2C57.4 31.2 60.5999 35.5 61.6999 40.3C61.7999 41 62.0999 42.4 62.0999 43.8C62.1999 45.6 60.7 47.1 59 47.2C55.2999 47.4 51.3999 45.6 50.5999 42.9C50.0999 41.2 48.7999 39.7 47.0999 39.3C45.3999 38.9 43.7999 39.4 42.6999 40.5C41.4999 41.6 41 43.2 41.3 44.8C41.7 47 43.7999 48.7 46.1999 48.7C61.0999 48.8 71.9 59.1 71.9 74C71.9 80.2 70.6999 85.2 67.5999 89.5C63.6999 95.2 48.0999 109 48.0999 109C46.8999 110.1 45.1 110.1 44 109Z"
            fill="#0043FF"
        />
        <path
            d="M12 76.4C4.29999 67.9 0.0999876 57 0.199988 45.4C0.399988 21.6 19.5 1.49995 43.3 0.199955C69.6 -1.20004 91.5 19.8 91.5 45.8C91.5 52.3 90.1 58.7 87.5 64.6C86.8 66.1 85 66.8 83.5 66L78.5 63.3C77.2 62.6 76.6 61 77.2 59.7C79.1 55.4 80.1 50.6 80.1 45.8C80.1 26.4 63.9 10.7 44.3 11.6C26.6 12.4 12.1 27.3 11.7 45C11.5 53.8 14.6 62.1 20.4 68.5C21.4 69.6 21.3 71.3 20.3 72.4L16.4 76.5C15.1 77.7 13.2 77.6 12 76.4Z"
            fill="url(#paint0_linear_3208_12432)"
        />
        <path
            d="M44 109L18.4 83.4C17.3 82.3 17.3 80.5 18.4 79.4L22.4 75.4C23.5 74.3 25.3 74.3 26.4 75.4L44 92.8C45.1 93.9 46.9 93.9 48 92.8C48 92.8 53.9999 87.3 55.6999 85.1C63.0999 75.7 59.5999 61.4 47.5999 60.1C47.0999 60 46.5 60 46 60C38.1 59.9 31.2 54.1 29.9 46.6C29 41.2 30.7999 35.8 34.6999 32.1C39.4999 27.6 46.6 26.3 52.9 29.2C57.4 31.2 60.5999 35.5 61.6999 40.3C61.7999 41 62.0999 42.4 62.0999 43.8C62.1999 45.6 60.7 47.1 59 47.2C55.2999 47.4 51.3999 45.6 50.5999 42.9C50.0999 41.2 48.7999 39.7 47.0999 39.3C45.3999 38.9 43.7999 39.4 42.6999 40.5C41.4999 41.6 41 43.2 41.3 44.8C41.7 47 43.7999 48.7 46.1999 48.7C61.0999 48.8 71.9 59.1 71.9 74C71.9 80.2 70.6999 85.2 67.5999 89.5C63.6999 95.2 48.0999 109 48.0999 109C46.8999 110.1 45.1 110.1 44 109Z"
            fill="url(#paint1_linear_3208_12432)"
        />
        <defs>
            <linearGradient
                id="paint0_linear_3208_12432"
                x1="45.8972"
                y1="109.841"
                x2="45.8972"
                y2="0.111454"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.046875" stopColor="#5697F5" />
                <stop offset="0.4948" stopColor="#5200C6" />
                <stop offset="1" stopColor="#232A92" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_3208_12432"
                x1="45.8972"
                y1="109.841"
                x2="45.8972"
                y2="0.111498"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.046875" stopColor="#5697F5" />
                <stop offset="0.4948" stopColor="#5200C6" />
                <stop offset="1" stopColor="#232A92" />
            </linearGradient>
        </defs>
    </svg>
);

export const LogoBusiness = (props: {
    style?: React.CSSProperties;
    alt?: string;
    fill?: string;
}): JSX.Element => (
    <svg
        viewBox="0 0 492 136"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M12 76.9C4.29999 68.4 0.0999876 57.5 0.199988 45.9C0.399988 22.1 19.5 1.99995 43.3 0.699955C69.6 -0.700045 91.5 20.3 91.5 46.3C91.5 52.8 90.1 59.2 87.5 65.1C86.8 66.6 85 67.3 83.5 66.5L78.5 63.8C77.2 63.1 76.6 61.5 77.2 60.2C79.1 55.9 80.1 51.1 80.1 46.3C80.1 26.9 63.9 11.2 44.3 12.1C26.6 12.9 12.1 27.8 11.7 45.5C11.5 54.3 14.6 62.6 20.4 69C21.4 70.1 21.3 71.8 20.3 72.9L16.4 77C15.1 78.2 13.2 78.1 12 76.9Z"
            fill="url(#paint0_linear_3208_12456)"
        />
        <path
            d="M44 109.5L18.4 83.8999C17.3 82.7999 17.3 80.9999 18.4 79.8999L22.4 75.8999C23.5 74.7999 25.3 74.7999 26.4 75.8999L43.9 93.3998C45 94.4998 46.8 94.4998 47.9 93.3998C47.9 93.3998 53.9 87.8998 55.6 85.6998C63 76.2998 59.5 61.9998 47.5 60.6998C47 60.5998 46.4 60.5998 45.9 60.5998C38 60.4998 31.1 54.6998 29.8 47.1998C28.9 41.7998 30.7 36.3998 34.6 32.6998C39.4 28.1998 46.5 26.8999 52.8 29.7999C57.3 31.7999 60.5 36.0999 61.6 40.8999C61.7 41.5999 62 42.9999 62 44.3999C62.1 46.1999 60.6 47.6999 58.9 47.7999C55.2 47.9999 51.3 46.1999 50.5 43.4999C50 41.7999 48.7 40.2999 47 39.8999C45.3 39.4999 43.7 39.9998 42.6 41.0998C41.4 42.1998 40.9 43.7999 41.2 45.3999C41.6 47.5999 43.7 49.2999 46.1 49.2999C61 49.3999 71.8 59.6999 71.8 74.5998C71.8 80.7998 70.6 85.7999 67.5 90.0999C63.6 95.7999 48 109.6 48 109.6C46.9 110.6 45.1 110.6 44 109.5Z"
            fill="url(#paint1_linear_3208_12456)"
        />
        <path
            d="M12 76.9C4.29999 68.4 0.0999876 57.5 0.199988 45.9C0.399988 22.1 19.5 1.99995 43.3 0.699955C69.6 -0.700045 91.5 20.3 91.5 46.3C91.5 52.8 90.1 59.2 87.5 65.1C86.8 66.6 85 67.3 83.5 66.5L78.5 63.8C77.2 63.1 76.6 61.5 77.2 60.2C79.1 55.9 80.1 51.1 80.1 46.3C80.1 26.9 63.9 11.2 44.3 12.1C26.6 12.9 12.1 27.8 11.7 45.5C11.5 54.3 14.6 62.6 20.4 69C21.4 70.1 21.3 71.8 20.3 72.9L16.4 77C15.1 78.2 13.2 78.1 12 76.9Z"
            fill="url(#paint2_linear_3208_12456)"
        />
        <path
            d="M44 109.5L18.4 83.8999C17.3 82.7999 17.3 80.9999 18.4 79.8999L22.4 75.8999C23.5 74.7999 25.3 74.7999 26.4 75.8999L43.9 93.3998C45 94.4998 46.8 94.4998 47.9 93.3998C47.9 93.3998 53.9 87.8998 55.6 85.6998C63 76.2998 59.5 61.9998 47.5 60.6998C47 60.5998 46.4 60.5998 45.9 60.5998C38 60.4998 31.1 54.6998 29.8 47.1998C28.9 41.7998 30.7 36.3998 34.6 32.6998C39.4 28.1998 46.5 26.8999 52.8 29.7999C57.3 31.7999 60.5 36.0999 61.6 40.8999C61.7 41.5999 62 42.9999 62 44.3999C62.1 46.1999 60.6 47.6999 58.9 47.7999C55.2 47.9999 51.3 46.1999 50.5 43.4999C50 41.7999 48.7 40.2999 47 39.8999C45.3 39.4999 43.7 39.9998 42.6 41.0998C41.4 42.1998 40.9 43.7999 41.2 45.3999C41.6 47.5999 43.7 49.2999 46.1 49.2999C61 49.3999 71.8 59.6999 71.8 74.5998C71.8 80.7998 70.6 85.7999 67.5 90.0999C63.6 95.7999 48 109.6 48 109.6C46.9 110.6 45.1 110.6 44 109.5Z"
            fill="url(#paint3_linear_3208_12456)"
        />
        <path
            d="M152 50.2999C151.7 50.6999 151.4 51.0999 151.1 51.2999C150.8 51.4999 150.4 51.5999 149.8 51.5999C149.2 51.5999 148.6 51.3999 148 50.9999C147.4 50.5999 146.7 50.1999 145.8 49.6999C145 49.1999 143.9 48.7999 142.7 48.3999C141.5 47.9999 140 47.7999 138.2 47.7999C135.9 47.7999 133.8 48.1999 132.1 48.9999C130.4 49.7999 128.9 50.9999 127.7 52.5999C126.5 54.0999 125.6 55.9999 125.1 58.1999C124.5 60.3999 124.2 62.8999 124.2 65.5999C124.2 68.4999 124.5 70.9999 125.1 73.1999C125.7 75.3999 126.6 77.2999 127.8 78.7999C129 80.2999 130.4 81.4999 132.1 82.2999C133.8 83.0999 135.7 83.4999 137.7 83.4999C139.8 83.4999 141.4 83.2999 142.7 82.7999C144 82.2999 145.1 81.7999 145.9 81.1999C146.8 80.5999 147.5 80.0999 148.1 79.5999C148.7 79.0999 149.4 78.8999 150.1 78.8999C151 78.8999 151.6 79.1999 152.1 79.8999L155.1 83.7999C153.9 85.2999 152.5 86.5999 151 87.5999C149.5 88.5999 147.9 89.4999 146.3 90.0999C144.6 90.6999 142.9 91.1999 141.1 91.4999C139.3 91.7999 137.5 91.8998 135.7 91.8998C132.5 91.8998 129.6 91.2999 126.9 90.0999C124.2 88.8999 121.8 87.1999 119.7 84.9999C117.7 82.7999 116.1 79.9999 114.9 76.7999C113.7 73.5999 113.1 69.8999 113.1 65.6999C113.1 61.9999 113.6 58.4999 114.7 55.2999C115.8 52.0999 117.3 49.2999 119.3 46.9999C121.3 44.6999 123.9 42.8999 126.9 41.5999C129.9 40.2999 133.4 39.5999 137.3 39.5999C141 39.5999 144.2 40.1999 147 41.3999C149.8 42.5999 152.3 44.2999 154.5 46.4999L152 50.2999Z"
            fill="url(#paint4_linear_3208_12456)"
        />
        <path
            d="M182.9 39.5C186.6 39.5 190 40.1 193.1 41.3C196.1 42.5 198.7 44.3 200.9 46.5C203 48.8 204.7 51.5 205.8 54.7C207 57.9 207.5 61.5 207.5 65.5C207.5 69.5 206.9 73.1 205.8 76.3C204.6 79.5 203 82.3 200.9 84.5C198.8 86.8 196.2 88.5 193.1 89.7C190.1 90.9 186.7 91.5 182.9 91.5C179.1 91.5 175.7 90.9 172.7 89.7C169.7 88.5 167.1 86.7 164.9 84.5C162.7 82.2 161.1 79.5 159.9 76.3C158.7 73.1 158.2 69.5 158.2 65.5C158.2 61.5 158.8 57.9 159.9 54.7C161.1 51.5 162.7 48.8 164.9 46.5C167.1 44.2 169.6 42.5 172.7 41.3C175.7 40.1 179.1 39.5 182.9 39.5ZM182.9 83.4C187.5 83.4 190.9 81.9 193.1 78.8C195.3 75.7 196.5 71.3 196.5 65.6C196.5 59.9 195.4 55.5 193.1 52.4C190.9 49.3 187.5 47.8 182.9 47.8C178.3 47.8 174.8 49.4 172.6 52.4C170.3 55.5 169.2 59.9 169.2 65.6C169.2 71.3 170.3 75.7 172.6 78.8C174.8 81.9 178.3 83.4 182.9 83.4Z"
            fill="url(#paint5_linear_3208_12456)"
        />
        <path
            d="M258.5 40.3V91H252C250.6 91 249.7 90.3 249.3 89L248.5 84.3C247.4 85.4 246.3 86.5 245.1 87.4C243.9 88.3 242.7 89.1 241.3 89.8C240 90.5 238.6 91 237 91.3C235.5 91.7 233.9 91.8 232.2 91.8C229.4 91.8 227 91.3 224.8 90.4C222.7 89.5 220.9 88.2 219.5 86.5C218.1 84.8 217 82.8 216.2 80.4C215.5 78 215.1 75.4 215.1 72.6V40.3H226V72.6C226 76 226.8 78.7 228.4 80.6C230 82.5 232.4 83.4 235.6 83.4C238 83.4 240.1 82.9 242.2 81.8C244.2 80.7 246.2 79.2 247.9 77.4V40.4H258.5V40.3Z"
            fill="url(#paint6_linear_3208_12456)"
        />
        <path
            d="M279.7 49.7C281.3 46.5 283.2 44 285.5 42.1C287.8 40.3 290.5 39.3 293.6 39.3C294.7 39.3 295.7 39.4 296.7 39.7C297.7 39.9 298.5 40.3 299.3 40.8L298.6 48.9C298.5 49.5 298.3 49.9 298 50.1C297.7 50.3 297.3 50.4 296.9 50.4C296.4 50.4 295.7 50.3 294.8 50.1C293.9 49.9 292.9 49.8 291.9 49.8C290.4 49.8 289.1 50 287.9 50.4C286.8 50.8 285.7 51.5 284.8 52.3C283.9 53.1 283.1 54.1 282.4 55.3C281.7 56.5 281.1 57.9 280.5 59.4V91H269.8V40.3H276C277.1 40.3 277.9 40.5 278.3 40.9C278.7 41.3 279 42 279.2 43.1L279.7 49.7Z"
            fill="url(#paint7_linear_3208_12456)"
        />
        <path
            d="M328.5 91.8C324.3 91.8 321 90.6 318.8 88.2C316.5 85.8 315.4 82.5 315.4 78.2V48.7H309.8C309.2 48.7 308.7 48.5 308.3 48.1C307.9 47.7 307.7 47.1 307.7 46.4V42.1L315.9 40.9L318.2 26.2C318.4 25.6 318.6 25.2 319 24.8C319.4 24.5 319.9 24.3 320.6 24.3H326.1V41H340.2V48.7H326V77.5C326 79.3 326.4 80.7 327.3 81.7C328.2 82.7 329.4 83.1 330.9 83.1C331.7 83.1 332.4 83 333 82.8C333.6 82.6 334.1 82.4 334.5 82.1C334.9 81.9 335.3 81.6 335.6 81.4C335.9 81.2 336.2 81.1 336.5 81.1C337.1 81.1 337.6 81.4 337.9 82.1L341.1 87.3C339.4 88.8 337.5 89.9 335.2 90.7C333.2 91.4 330.9 91.8 328.5 91.8Z"
            fill="url(#paint8_linear_3208_12456)"
        />
        <path
            d="M380.8 49.5C380.5 50 380.2 50.3 379.9 50.5C379.6 50.7 379.2 50.8 378.7 50.8C378.1 50.8 377.5 50.6 376.9 50.3C376.2 50 375.5 49.6 374.6 49.1C373.7 48.7 372.7 48.3 371.4 47.9C370.2 47.6 368.8 47.4 367.2 47.4C365.9 47.4 364.7 47.6 363.6 47.9C362.5 48.2 361.6 48.7 360.9 49.2C360.2 49.8 359.6 50.5 359.2 51.2C358.8 52 358.6 52.8 358.6 53.7C358.6 54.9 359 55.9 359.7 56.7C360.4 57.5 361.4 58.2 362.6 58.8C363.8 59.4 365.2 59.9 366.7 60.4C368.2 60.9 369.8 61.4 371.4 61.9C373 62.4 374.6 63 376.1 63.7C377.6 64.4 379 65.2 380.2 66.3C381.4 67.3 382.4 68.5 383.1 70C383.8 71.5 384.2 73.2 384.2 75.2C384.2 77.6 383.8 79.8 382.9 81.8C382 83.8 380.8 85.6 379.1 87.1C377.4 88.6 375.4 89.8 372.9 90.6C370.4 91.4 367.6 91.8 364.4 91.8C362.6 91.8 360.9 91.6 359.3 91.3C357.7 91 356.1 90.6 354.6 90C353.1 89.5 351.7 88.8 350.5 88C349.2 87.2 348.1 86.4 347.1 85.5L349.6 81.4C349.9 80.9 350.3 80.5 350.7 80.2C351.1 79.9 351.7 79.8 352.4 79.8C353.1 79.8 353.8 80 354.5 80.5C355.2 80.9 356 81.4 356.9 82C357.8 82.5 358.9 83 360.2 83.5C361.5 83.9 363.1 84.2 365 84.2C366.6 84.2 368 84 369.1 83.6C370.3 83.2 371.2 82.7 372 82C372.8 81.3 373.3 80.6 373.7 79.7C374.1 78.9 374.2 78 374.2 77C374.2 75.7 373.8 74.6 373.1 73.8C372.4 73 371.4 72.3 370.2 71.7C369 71.1 367.6 70.6 366.1 70.1C364.6 69.6 363 69.1 361.4 68.6C359.8 68.1 358.2 67.5 356.7 66.8C355.2 66.1 353.8 65.2 352.6 64.1C351.4 63 350.4 61.7 349.7 60.2C349 58.7 348.6 56.8 348.6 54.6C348.6 52.6 349 50.7 349.8 48.8C350.6 47 351.8 45.4 353.4 44C355 42.6 356.9 41.5 359.2 40.7C361.5 39.9 364.2 39.5 367.2 39.5C370.6 39.5 373.7 40 376.5 41.1C379.3 42.2 381.6 43.7 383.5 45.6L380.8 49.5Z"
            fill="url(#paint9_linear_3208_12456)"
        />
        <path
            d="M404.9 26.4C404.9 27.4 404.7 28.2999 404.3 29.0999C403.9 29.9999 403.4 30.7 402.7 31.4C402 32 401.3 32.6 400.4 32.9C399.5 33.3 398.6 33.5 397.6 33.5C396.6 33.5 395.7 33.3 394.9 32.9C394.1 32.5 393.3 32 392.7 31.4C392.1 30.8 391.6 29.9999 391.2 29.0999C390.8 28.1999 390.6 27.3 390.6 26.4C390.6 25.4 390.8 24.4999 391.2 23.5999C391.6 22.6999 392.1 21.9999 392.7 21.2999C393.3 20.6999 394.1 20.0999 394.9 19.7999C395.7 19.3999 396.6 19.2 397.6 19.2C398.6 19.2 399.5 19.3999 400.4 19.7999C401.3 20.1999 402 20.6999 402.7 21.2999C403.4 21.8999 403.9 22.6999 404.3 23.5999C404.7 24.4999 404.9 25.4 404.9 26.4ZM403.1 40.3V91H392.4V40.3H403.1Z"
            fill="url(#paint10_linear_3208_12456)"
        />
        <path
            d="M430.8 91.8C426.6 91.8 423.3 90.6 421.1 88.2C418.8 85.8 417.7 82.5 417.7 78.2V48.7H412.1C411.5 48.7 411 48.5 410.6 48.1C410.2 47.7 410 47.1 410 46.4V42.1L418.2 40.9L420.5 26.2C420.7 25.6 420.9 25.2 421.3 24.8C421.7 24.5 422.2 24.3 422.9 24.3H428.4V41H442.5V48.7H428.4V77.5C428.4 79.3 428.8 80.7 429.7 81.7C430.6 82.7 431.8 83.1 433.3 83.1C434.1 83.1 434.8 83 435.4 82.8C436 82.6 436.5 82.4 436.9 82.1C437.3 81.9 437.7 81.6 438 81.4C438.3 81.2 438.6 81.1 438.9 81.1C439.5 81.1 440 81.4 440.3 82.1L443.5 87.3C441.8 88.8 439.9 89.9 437.6 90.7C435.6 91.4 433.2 91.8 430.8 91.8Z"
            fill="url(#paint11_linear_3208_12456)"
        />
        <path
            d="M456.4 66.4999C456.5 69.3999 457 71.8999 457.7 73.9999C458.5 76.0999 459.5 77.8999 460.8 79.1999C462.1 80.5999 463.6 81.5999 465.4 82.2999C467.2 82.9999 469.2 83.2999 471.3 83.2999C473.4 83.2999 475.2 83.0999 476.7 82.5999C478.2 82.0999 479.6 81.5999 480.7 80.9999C481.8 80.3999 482.8 79.8999 483.6 79.3999C484.4 78.8999 485.1 78.6999 485.8 78.6999C486.7 78.6999 487.3 78.9999 487.8 79.6999L490.8 83.5999C489.5 85.0999 488.1 86.3999 486.5 87.3999C484.9 88.3999 483.2 89.2999 481.4 89.8999C479.6 90.4999 477.8 90.9999 475.9 91.2999C474 91.5999 472.2 91.6999 470.4 91.6999C466.9 91.6999 463.6 91.0999 460.6 89.8999C457.6 88.6999 455 86.9999 452.8 84.6999C450.6 82.3999 448.9 79.5999 447.6 76.1999C446.3 72.7999 445.7 68.8999 445.7 64.4999C445.7 60.9999 446.3 57.6999 447.4 54.6999C448.5 51.6999 450.1 48.9999 452.2 46.6999C454.3 44.3999 456.8 42.6999 459.8 41.3999C462.8 40.0999 466.1 39.3999 469.9 39.3999C473 39.3999 475.9 39.8999 478.6 40.8999C481.3 41.8999 483.5 43.3999 485.5 45.2999C487.4 47.2999 488.9 49.6999 490 52.4999C491.1 55.2999 491.6 58.5999 491.6 62.1999C491.6 63.8999 491.4 64.9999 491.1 65.4999C490.7 66.0999 490.1 66.2999 489.1 66.2999H456.4V66.4999ZM481.9 59.9999C481.9 58.1999 481.6 56.4999 481.1 54.9999C480.6 53.4999 479.8 52.0999 478.8 50.9999C477.8 49.8999 476.6 48.9999 475.1 48.2999C473.6 47.6999 471.9 47.2999 470 47.2999C466.1 47.2999 463.1 48.3999 460.9 50.5999C458.7 52.7999 457.3 55.8999 456.7 59.8999H481.9V59.9999Z"
            fill="url(#paint12_linear_3208_12456)"
        />
        <path
            d="M267.6 117.4H264.9V114.5H267.6V113C267.6 108.3 269.9 106.2 275.3 106.2V109.1C272.2 109.1 271.1 110.2 271.1 113V114.5H275.5V117.4H271.1V135.5H267.6V117.4Z"
            fill="#999999"
        />
        <path
            d="M288.7 135.8C282.8 135.8 278.3 131.6 278.3 124.9C278.3 118.3 283 114.1 288.9 114.1C294.9 114.1 299.5 118.3 299.5 124.9C299.5 131.6 294.7 135.8 288.7 135.8ZM288.7 132.7C292.3 132.7 295.9 130.2 295.9 124.9C295.9 119.6 292.4 117.1 288.8 117.1C285.1 117.1 281.8 119.6 281.8 124.9C281.8 130.3 285.1 132.7 288.7 132.7Z"
            fill="#999999"
        />
        <path
            d="M307.5 135.5H304V114.5H307.5V117.9C308.7 115.6 310.9 114.1 314.3 114.1V117.7H313.4C310.1 117.7 307.5 119.2 307.5 124V135.5V135.5Z"
            fill="#999999"
        />
        <path
            d="M339.9 114.1C345.6 114.1 349.8 118.3 349.8 124.9C349.8 131.4 345.5 135.8 339.9 135.8C336.1 135.8 333.4 133.9 332.1 131.6V135.5H328.6V107.2H332.1V118.4C333.4 116.1 336.2 114.1 339.9 114.1ZM339.1 117.2C335.3 117.2 332 120.1 332 125C332 129.9 335.3 132.8 339.1 132.8C343 132.8 346.2 129.9 346.2 125C346.3 120 343 117.2 339.1 117.2Z"
            fill="#999999"
        />
        <path
            d="M373 135.5H369.5V132.4C368.2 134.6 365.6 135.8 362.8 135.8C358 135.8 354.2 132.8 354.2 126.8V114.5H357.6V126.3C357.6 130.5 359.9 132.7 363.5 132.7C367.1 132.7 369.5 130.5 369.5 126V114.5H373V135.5V135.5Z"
            fill="#999999"
        />
        <path
            d="M386.3 135.8C381.4 135.8 378 133.1 377.7 129.3H381.3C381.5 131.3 383.3 132.9 386.2 132.9C389 132.9 390.5 131.5 390.5 129.8C390.5 125.1 378.1 127.8 378.1 120C378.1 116.8 381.1 114.1 385.8 114.1C390.4 114.1 393.5 116.6 393.7 120.7H390.2C390 118.6 388.4 117.1 385.6 117.1C383 117.1 381.5 118.3 381.5 120C381.5 125 393.7 122.3 393.8 129.8C394 133.2 390.9 135.8 386.3 135.8Z"
            fill="#999999"
        />
        <path
            d="M398.3 108.7C398.3 107.4 399.3 106.3 400.7 106.3C402 106.3 403 107.3 403 108.7C403 110 402 111.1 400.7 111.1C399.3 111.1 398.3 110.1 398.3 108.7ZM398.9 114.5H402.4V135.5H398.9V114.5Z"
            fill="#999999"
        />
        <path
            d="M423.6 123.6C423.6 119.3 421.3 117.1 417.7 117.1C414.1 117.1 411.7 119.3 411.7 123.8V135.4H408.2V114.4H411.7V117.4C413.1 115.2 415.6 114 418.4 114C423.3 114 427 117 427 123V135.4H423.6V123.6V123.6Z"
            fill="#999999"
        />
        <path
            d="M441.8 135.8C435.8 135.8 431.4 131.6 431.4 124.9C431.4 118.3 435.6 114.1 441.8 114.1C447.9 114.1 451.9 118.4 451.9 124.1C451.9 124.9 451.9 125.5 451.8 126.2H435C435.3 130.4 438.3 132.8 441.8 132.8C444.9 132.8 446.9 131.2 447.7 128.9H451.4C450.4 132.8 447 135.8 441.8 135.8ZM435 123.4H448.2C448.2 119.3 445.2 117 441.6 117C438.3 117.1 435.4 119.4 435 123.4Z"
            fill="#999999"
        />
        <path
            d="M463.9 135.8C459 135.8 455.6 133.1 455.3 129.3H458.9C459.1 131.3 460.9 132.9 463.8 132.9C466.6 132.9 468.1 131.5 468.1 129.8C468.1 125.1 455.7 127.8 455.7 120C455.7 116.8 458.7 114.1 463.4 114.1C468 114.1 471.1 116.6 471.3 120.7H467.8C467.6 118.6 466 117.1 463.2 117.1C460.6 117.1 459.1 118.3 459.1 120C459.1 125 471.3 122.3 471.4 129.8C471.5 133.2 468.5 135.8 463.9 135.8Z"
            fill="#999999"
        />
        <path
            d="M483.9 135.8C479 135.8 475.6 133.1 475.3 129.3H478.9C479.1 131.3 480.9 132.9 483.8 132.9C486.6 132.9 488.1 131.5 488.1 129.8C488.1 125.1 475.7 127.8 475.7 120C475.7 116.8 478.7 114.1 483.4 114.1C488 114.1 491.1 116.6 491.3 120.7H487.8C487.6 118.6 486 117.1 483.2 117.1C480.6 117.1 479.1 118.3 479.1 120C479.1 125 491.3 122.3 491.4 129.8C491.5 133.2 488.5 135.8 483.9 135.8Z"
            fill="#999999"
        />
        <defs>
            <linearGradient
                id="paint0_linear_3208_12456"
                x1="45.8954"
                y1="0.611555"
                x2="45.8954"
                y2="77.8514"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#232A92" />
                <stop offset="0.5052" stopColor="#5200C6" />
                <stop offset="0.9531" stopColor="#5697F5" />
            </linearGradient>
            <linearGradient
                id="paint1_linear_3208_12456"
                x1="44.7017"
                y1="28.1924"
                x2="44.7017"
                y2="110.341"
                gradientUnits="userSpaceOnUse"
            >
                <stop stopColor="#232A92" />
                <stop offset="0.5052" stopColor="#5200C6" />
                <stop offset="0.9531" stopColor="#5697F5" />
            </linearGradient>
            <linearGradient
                id="paint2_linear_3208_12456"
                x1="45.8954"
                y1="110.341"
                x2="45.8954"
                y2="0.611552"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.046875" stopColor="#5697F5" />
                <stop offset="0.4948" stopColor="#5200C6" />
                <stop offset="1" stopColor="#232A92" />
            </linearGradient>
            <linearGradient
                id="paint3_linear_3208_12456"
                x1="45.8954"
                y1="110.341"
                x2="45.8954"
                y2="0.611454"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.046875" stopColor="#5697F5" />
                <stop offset="0.4948" stopColor="#5200C6" />
                <stop offset="1" stopColor="#232A92" />
            </linearGradient>
            <linearGradient
                id="paint4_linear_3208_12456"
                x1="134.326"
                y1="91.7802"
                x2="134.326"
                y2="39.5291"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.046875" stopColor="#5697F5" />
                <stop offset="0.4948" stopColor="#5200C6" />
                <stop offset="1" stopColor="#232A92" />
            </linearGradient>
            <linearGradient
                id="paint5_linear_3208_12456"
                x1="182.852"
                y1="91.7804"
                x2="182.852"
                y2="39.5292"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.046875" stopColor="#5697F5" />
                <stop offset="0.4948" stopColor="#5200C6" />
                <stop offset="1" stopColor="#232A92" />
            </linearGradient>
            <linearGradient
                id="paint6_linear_3208_12456"
                x1="236.896"
                y1="91.8313"
                x2="236.896"
                y2="40.3249"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.046875" stopColor="#5697F5" />
                <stop offset="0.4948" stopColor="#5200C6" />
                <stop offset="1" stopColor="#232A92" />
            </linearGradient>
            <linearGradient
                id="paint7_linear_3208_12456"
                x1="284.376"
                y1="91.0357"
                x2="284.376"
                y2="39.3792"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.046875" stopColor="#5697F5" />
                <stop offset="0.4948" stopColor="#5200C6" />
                <stop offset="1" stopColor="#232A92" />
            </linearGradient>
            <linearGradient
                id="paint8_linear_3208_12456"
                x1="324.448"
                y1="91.8314"
                x2="324.448"
                y2="24.3639"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.046875" stopColor="#5697F5" />
                <stop offset="0.4948" stopColor="#5200C6" />
                <stop offset="1" stopColor="#232A92" />
            </linearGradient>
            <linearGradient
                id="paint9_linear_3208_12456"
                x1="365.393"
                y1="91.8313"
                x2="365.393"
                y2="39.5292"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.046875" stopColor="#5697F5" />
                <stop offset="0.4948" stopColor="#5200C6" />
                <stop offset="1" stopColor="#232A92" />
            </linearGradient>
            <linearGradient
                id="paint10_linear_3208_12456"
                x1="397.757"
                y1="91.0357"
                x2="397.757"
                y2="19.2229"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.046875" stopColor="#5697F5" />
                <stop offset="0.4948" stopColor="#5200C6" />
                <stop offset="1" stopColor="#232A92" />
            </linearGradient>
            <linearGradient
                id="paint11_linear_3208_12456"
                x1="426.818"
                y1="91.8314"
                x2="426.818"
                y2="24.3639"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.046875" stopColor="#5697F5" />
                <stop offset="0.4948" stopColor="#5200C6" />
                <stop offset="1" stopColor="#232A92" />
            </linearGradient>
            <linearGradient
                id="paint12_linear_3208_12456"
                x1="468.657"
                y1="91.7803"
                x2="468.657"
                y2="39.5291"
                gradientUnits="userSpaceOnUse"
            >
                <stop offset="0.046875" stopColor="#5697F5" />
                <stop offset="0.4948" stopColor="#5200C6" />
                <stop offset="1" stopColor="#232A92" />
            </linearGradient>
        </defs>
    </svg>
);
